import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "../hooks/Sortable/Table";
import { WorkspaceRoleName, RoleName } from "../models/roleNames";
import { AppRolesContext } from "../../contexts/AppRolesContext";

import { Title } from "../ui/Title";
import { Selectbox } from "../ui/Selectbox";
import { SearchBox } from "../ui/SearchBox";

import './TRECoreWorkspaceData.css';
import { WorkspaceType } from "../models/workspace";

interface workspaceInt {
  name: string;
  workspace_desc: string;
  active: string;
  overview:string
}

type TRECoreWorkspaceDataProps = {
  wslistParam: any;
  updatedWorkspace: null | string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setCanSeeCosts: React.Dispatch<React.SetStateAction<boolean>>;
  canSeeCosts: boolean;
}

let filterData:any ;

/**
 * Renders information about the workspace obtained from TRE Core API
 * @param props 
 */

export const TRECoreWorkspaceData = ({ wslistParam, updatedWorkspace, setRefresh, canSeeCosts, setCanSeeCosts }: TRECoreWorkspaceDataProps ) => {
  const appRolesCtx = useContext(AppRolesContext);
  const [wsData, setWsData] = useState<any>([]);
  const [wsDataAll, setWsDataAll] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState<string | null>();
  const [sort, setSort] = useState<string | null>();
  const [sortCUPAsc, setSortCUPAsc] = useState(false);
  const [wsUpdating, setWorkspaceUpdating] = useState<boolean>(false);
  let navigate = useNavigate();
  
  let columns = [
    { label: "Workspace name", accessor: "name", sortable: true },
    { label: "Status", accessor: "active", sortable: true },
    { label: "Deployment status", accessor: "deploymentStatus", sortable: true },
  ];

  if (canSeeCosts) {
    columns.splice(1, 0, { label: "Id", accessor: "id", sortable: true })
    columns.splice(2, 0, { label: "CUP", accessor: "cup", sortable: true })
  } else {
    columns.splice(1, 0, { label: "Description", accessor: "workspace_desc", sortable: true })
  }

  useEffect(() => {
    if (appRolesCtx.roles?.includes(RoleName.TREAdmin)) {
      setSearchResult(() => wslistParam);
      setWsData(wslistParam);
      setWsDataAll(wslistParam);
    } else {
      filterData = wslistParam.filter((element:any) => element.isEnabled === true);
      setWsData(filterData);
      setWsDataAll(filterData);
    }
  }, [wslistParam]);

  const routeChange = (path:string) => {
    navigate(path);
  }

  const searchWorkspaces = (e: string, workspaces: WorkspaceType[] = wsDataAll) => {
    const searchTerm = e.toLowerCase();
    let newIds = workspaces;
    let newNames = workspaces;

    const filteredByIds = newIds.filter((item: any) => item.id?.toLowerCase().includes(searchTerm));
    const filteredByName = newNames.filter((item: any) => item.properties.display_name?.toLowerCase().includes(searchTerm));

    const searchWorkspaces = filteredByIds.concat(filteredByName);

    const newWorkspaces: any = Array.from(new Set(searchWorkspaces));
    return newWorkspaces;
  }

  const sortWorkspaces = (e: string, workspaces: any = wsDataAll) => {
    const sortedCUPs = (selected: string) => {
      setSortCUPAsc(!sortCUPAsc);
      let sortedData = workspaces;

      sortCUPAsc ? (
        sortedData.sort((a: any, b: any) => a.costs[0]?.credit_percentage_usage - b.costs[0]?.credit_percentage_usage)
      ) : (
        sortedData.sort((a: any, b: any) => b.costs[0]?.credit_percentage_usage - a.costs[0]?.credit_percentage_usage)
      );
      return sortedData;
    }
    const sortedData = (selected: string) => {
      let sortedData = workspaces;
      
      sortedData.sort((a:any, b:any) => {
        const A = e === 'updatedWhen' ? a[selected] : a.properties.display_name?.toUpperCase();
        const B = e === 'updatedWhen' ? b[selected] : b.properties.display_name?.toUpperCase();

        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });

      return sortedData;
    }
    
    return e === "credit_percentage_usage" ? sortedCUPs(e) : sortedData(e);
  }

  const sortData = (sortedBy: string) => {
    setSort(sortedBy);
    searchedTerm ? setWsData(sortWorkspaces(sortedBy, searchWorkspaces(searchedTerm))) : setWsData([...sortWorkspaces(sortedBy)]);
  }

  const onSearch = (e: string) => {
    const searchTerm = e.toLowerCase();
    setSearchedTerm(searchTerm);
    sort ? setWsData(searchWorkspaces(searchTerm, sortWorkspaces(sort))) : setWsData(searchWorkspaces(searchTerm));
  }

  const sortByOptions = [
    {
      name: "Alphabetical order",
      value: "display_name"
    },
    {
      name: "Recently updated",
      value: "updatedWhen"
    }
  ];

  return (
    <div>
      <Title>Workspaces</Title>
        <div className="TRECoreWorkspaceData__sort-by-container">
          <Selectbox
            label="Sort by"
            options={sortByOptions}
            onChange={(e: string) => sortData(e)}
          />
          {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
            <>
              {wsUpdating ? (
                <p>A workspace is being deployed, you can create another workspace once this has completed</p>
              ) : (
                <button
                  className="govuk-button TRECoreWorkspaceData__create-workspace-button"
                  data-module="govuk-button"
                  onClick={()=> routeChange('/createworkspace')}
                >
                  Create workspace
                </button>
              )}
            
            </>
          )}
        </div>
        <SearchBox className="TRECoreWorkspaceData__search" placeholder="Search for workspace or ID" onSearch={(e) => onSearch(e.target.value)} />
        <Table
          data={wsData}
          columns={columns}
          updatedWorkspace={updatedWorkspace}
          setRefresh={setRefresh}
          sort={sort}
          sortData={sortData}
          searchedTerm={searchedTerm}
          onSearch={onSearch}
          setWorkspaceUpdating={setWorkspaceUpdating}
          canSeeCosts={canSeeCosts}
          setCanSeeCosts={setCanSeeCosts}
        />
    </div>
  );
};
