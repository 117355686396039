import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { graphConfig, loginRequest } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';
import { WorkspaceContext } from '../contexts/WorkspaceContext';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { TableHead } from '../components/ui/TableHead';
import { TextButton } from '../components/ui/TextButton';
import { SearchBox } from '../components/ui/SearchBox';
import { ButtonLink } from '../components/ui/ButtonLink';
import { MessageCard } from '../components/Error/MessageCard';
import { Lede } from '../components/ui/Lede';
import { CostFigures } from '../components/ui/CostFigures';
import { Td } from '../components/ui/GDS-components/Table';

import './ManageWorkspace.css';

export const ManageWorkspace = () => {
  const { instance, accounts } = useMsal();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [hasUsers, setHasUsers] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deploymentFailed, setDeploymentFailed] = useState(false);
  const workspaceCtx = useContext(WorkspaceContext);

  useEffect(() => {
    setIsLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then(async (res) => {
        await CallApiWithToken(res.accessToken, graphConfig.graphMeEndpoint, HttpMethod.Get, '')
          .then(async (response) => {
            await CallApiWithToken(res.accessToken, `${graphConfig.graphGroups}?$filter=displayName eq '${process.env.REACT_APP_ENV}-ws-${workspaceCtx.workspace.id.slice(-4)} ${graphConfig.graphGroupWorkspaceResearchers}'`, HttpMethod.Get, '')
              .then(async (response) => {
                console.log("response: ", response);
                return response.value.length > 0 ? (
                  await CallApiWithToken(res.accessToken, `${graphConfig.graphGroups}/${response.value[0].id}/members`, HttpMethod.Get, '')
                    .then(async (response) => {
                      return response.value.length > 0 ? (
                        setHasUsers(true),
                        setUsers(response.value),
                        setAllUsers(response.value),
                        setIsLoading(false)
                      ) : (
                        setHasUsers(false),
                        setIsLoading(false)
                      )
                    }).catch(err => {
                      setError(err);
                      setIsLoading(err);
                    })
                ) : (
                  setHasUsers(false),
                  setIsLoading(false)
                )
              })
              .catch(err => {
                setError(err);
                setIsLoading(false);
              })
          }).catch(err => {
            setError(err);
            setIsLoading(false);
          })
      })
    )
    workspaceCtx.workspace && setDeploymentFailed(workspaceCtx.workspace.deploymentStatus === "deployment_failed");
  }, [workspaceCtx]);

  const tableHeaders = [
    {
      header: "Users"
    },
    {
      header: "Email address",
      colSpan: workspaceCtx.workspace.templateName && workspaceCtx.workspace.templateName.includes("msl") ? 3 : 1
    }
  ];

  const onSearch = (value: string) => {
    const searchTerm = value.toLowerCase();
    let newEmailAddresses = allUsers;
    let newNames = allUsers;

    const filterByIds = newEmailAddresses.filter((item: any) => item.mail.toLowerCase().includes(searchTerm));
    const filteredByName = newNames.filter((item: any) => item.displayName.toLowerCase().includes(searchTerm));

    const searchUsers = filterByIds.concat(filteredByName);

    const newUsers = Array.from(new Set(searchUsers));
    return setUsers(newUsers);
  }

  return (
    <section className="manage-workspace">
      {deploymentFailed ? (
        <Lede>Deployment failed. Speak to your administrator</Lede>
      ) : (
        <>
          {workspaceCtx.workspace.properties && (
            <>
              <header className="manage-workspace__header">
                <div>
                  <Title>Manage workspace users</Title>
                  <Subtitle>{workspaceCtx.workspace.properties.display_name}</Subtitle>
                </div>
                <Link className="manage-workspace__link" to={`/manage-workspaces/${workspaceCtx.workspace.id}/manage-workspace-services`}>Manage workspace services</Link>
              </header>
              <CostFigures id={workspaceCtx.workspace.id} />
              {isLoading ? (
                <p>loading...</p>
              ) : (
                <>
                  {error ? (
                    <MessageCard msgData={error} />
                  ) : (
                    <>
                      <div className="manage-workspace__sort-wrapper">
                        <ButtonLink className="manage-workspace__invite" href="https://erap.cprd.com" target="_blank">Invite new user</ButtonLink>
                      </div>
                      <SearchBox
                        className="manage-workspace__search-bar"
                        placeholder="Search for a users name or email address"
                        onSearch={(e: any) => onSearch(e.target.value)}
                      />
                      {!hasUsers && <Lede className="manage-workspaces__no-users">This workspace does not have any users</Lede>}
                      {hasUsers && (
                        <table className="manage-workspace__table">
                          <TableHead headers={tableHeaders} />
                          <tbody>
                            {users && users.map((user: any) => {
                              return (
                                <User user={user} />
                              )
                            })}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </section>
  )
}

const User = ({ user }: any) => {
  const workspaceCtx = useContext(WorkspaceContext);

  const formatName = (displayName: string) => {
    const name = displayName.replace(".", " ");
    return name;
  }

  return (
    <tr>
      <Td className="manage-workspace__user-column">{formatName(user.displayName)}</Td>
      <Td>{user.mail}</Td>
      {workspaceCtx.workspace.templateName.includes("msl") && (
        <Td>
          <Link to={`#`}>Update</Link>
        </Td>
      )}
      {workspaceCtx.workspace.templateName.includes("msl") && (
        <Td>
          <TextButton onClick={() => console.log("not in place yet")}>Remove</TextButton>
        </Td>
      )}
    </tr>
  )
}
