import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { ErrorSummary, LabelText, Input, Button, HintText, ErrorText } from "govuk-react";

import { Title } from "../components/ui/Title";
import { FormWrapper } from "../components/ui/FormWrapper";

import { error } from "../types";
import { FormLabel } from "../components/ui/FormLabel";
import { FormButtonContainer } from "../components/ui/FormButtonContainer";
import { graphConfig, loginRequest, trecoreConfig } from "../components/Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { Selectbox } from "../components/ui/Selectbox";
import { Textarea } from "../components/ui/Textarea";

import './Support.css';
import { NewSupportRequest } from "../components/models/support";
import { NotificationBox } from "../components/ui/NotificationBox";

export const Support = () => {
  const [errors, setErrors] = useState<null | error[]>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [workspaces, setWorkspaces] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [requestSuccessful, setRequestSuccessful] = useState<null | string>(null);
  const [apiError, setApiError] = useState(null);
  const formRef = useRef<HTMLFormElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const workspaceInputRef = useRef<HTMLInputElement>(null);
  const issueTypeInputRef = useRef<HTMLInputElement>(null);
  const errorMessageInputRef = useRef<HTMLInputElement>(null);
  const issueDescriptionInputRef = useRef<HTMLInputElement>(null);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // get workspaces
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setWorkspaces(response.workspaces);
        setLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setLoading(false);
      })
    })
    // get profile
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        graphConfig.graphMeEndpoint,
        HttpMethod.Get,
        ''
      ).then(async (response) => {
        setName(`${response.givenName} ${response.surname}`);
        setEmail(response.mail);
        setLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setLoading(false);
      })
    })
  }, []);

  const resetForm = () => {
    formRef.current?.reset();
  }

  const makeApiCall = (payload: any) => {
    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      scopes: trecoreConfig.scopes,
      account: accounts[0]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Support}`,
        HttpMethod.Post,
        payload,
        "*"
      ).then(response => {
        // set success status here
        console.log("response: ", response);
        setRequestSuccessful("Your message has been sent");
        document.getElementById("support-page")?.scrollIntoView();
        resetForm();
        setLoading(false);
      }).catch((err: any) => {
        // set error status here
        console.log("err: ", err);
        setApiError(err.message);
        setLoading(false);
      })
    })
  }

  const createPayload = (e: any) => {
    e.preventDefault();

    const enteredName = nameInputRef.current!.value;
    const enteredEmail = emailInputRef.current!.value;
    const workspace = workspaceInputRef.current ? workspaceInputRef.current!.value : null;
    const issueType = issueTypeInputRef.current!.value;
    const errorMessage = errorMessageInputRef.current!.value;
    const issueDescription = issueDescriptionInputRef.current!.value;

    let properties: NewSupportRequest["properties"] = {};

    properties = {
      recipients: process.env.REACT_APP_SUPPORT_RECIPIENT,
      secondary_recipients: "",
      name: enteredName,
      email: enteredEmail,
      workspace_id: workspace,
      issue_name: issueType,
      error_message: errorMessage,
      issue_description: issueDescription
    }

    const newSupportRequest : NewSupportRequest = properties;
    makeApiCall(newSupportRequest);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (event: any) => {
    event.preventDefault();
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const inputRegex = /^[a-zA-Z0-9%&()\-_=+:';,.?\s]*$/;

    setRequestSuccessful(null);
    setErrors(null);
    const newErrors = [];

    // Keeping this incase we need to bring back secondary emails
    // const secondaryEmail = secondaryEmailInputRef.current?.value.trim();
    // const trimmedEmail = secondaryEmail && secondaryEmail.replace(/,\s*$/, "");
    // const emails = trimmedEmail && trimmedEmail.split(",");
    // const invalidEmails = [];

    // for (var i = 0; emails && i < emails.length; i++) {
    //   if (emails[i].trim() === "" || !emails[i].trim().match(emailRegEx)) {
    //     invalidEmails.push(emails[i]);
    //   }
    // }

    const workspace = workspaceInputRef.current?.value;
    if (workspace === "Select") {
      newErrors.push({
        targetName: "workspace",
        text: "Select a workspace"
      })
    }

    const issueType = issueTypeInputRef.current?.value;
    if (issueType === "Select") {
      newErrors.push({
        targetName: "issue-type",
        text: "Select a type of issue"
      })
    }

    const errorMessage = errorMessageInputRef.current?.value;
    if (errorMessage && (errorMessage.length < 10 || errorMessage.length > 400 || !errorMessage.match(inputRegex))) {
      newErrors.push({
        targetName: "error-message",
        text: "Enter a valid error message"
      })
    }

    const issue = issueDescriptionInputRef.current?.value;
    if (!issue || (issue.length < 10 || issue.length > 1000) || !issue.match(inputRegex)) {
      newErrors.push({
        targetName: "issue-description",
        text: "Enter a valid issue description"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("support-page")?.scrollIntoView()
    ) : createPayload(event);
  }

  const getWorkspaces = (workspaces: any) => {
    const newWorkspaces = workspaces.map((workspace: any) => {
      let obj = {
        name: "",
        value: ""
      }
      obj.name = `${workspace.properties.display_name} - ${workspace.id}`;
      obj.value = `${workspace.properties.display_name} - ${workspace.id}`;
      return obj;
    })

    return newWorkspaces.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  return (
    <section id="support-page">
      {(apiError || requestSuccessful) && (
        <NotificationBox
          id="notification"
          error={apiError}
          text={requestSuccessful}
        />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <Title>Support</Title>
      <div className="support__header">
        <p className="support__copy">To report errors or bugs, complete the form below.</p>
      </div>
      <form ref={formRef} onSubmit={onSubmit}>
        <FormWrapper>
          {name && email && (
            <>
              <FormLabel>
                <LabelText htmlFor="name" className="support__grey">Name</LabelText>
                <Input id="name" ref={nameInputRef} defaultValue={name} readOnly disabled />
              </FormLabel>
              <FormLabel>
                <LabelText htmlFor="email" className="support__grey">Email</LabelText>
                <Input id="email" ref={emailInputRef} defaultValue={email} readOnly disabled />
              </FormLabel>
            </>
          )}

          {workspaces && (
            <Selectbox
              id="workspace"
              className="support__select"
              label="Workspace"
              forwardRef={workspaceInputRef}
              error={hasError("workspace")}
              hint="Select the workspace experiencing the issue"
              errorText="Select a workspace"
              options={getWorkspaces(workspaces)}
            />
          )}

          <Selectbox
            id="issue-type"
            className="support__select"
            label="Type of issue"
            forwardRef={issueTypeInputRef}
            error={hasError("issue-type")}
            hint="Select the category for your issue to enable ticket triage."
            errorText="Select a type of issue"
            options={[
              {
                name: "Access to datacut",
                value: "Datacut-access"
              },
              {
                name: "Access to shared workspace",
                value: "Shared-workspace-access"
              },
              {
                name: "Access to VM",
                value: "Vm-access"
              },
              {
                name: "Airlock",
                value: "Airlock"
              },
              {
                name: "Costs - allowance usage",
                value: "Allowance-usage"
              },
              {
                name: "Data provided is not as expected",
                value: "Data-provided-not-as-expected"
              },
              {
                name: "Error in training materials (doesn't match usage)",
                value: "Training-materials-error"
              },
              {
                name: "Error message",
                value: "Error-message"
              },
              {
                name: "Error with tool provided",
                value: "Error-with-tool-provided"
              },
              {
                name: "License",
                value: "License"
              }
            ]}
          />

          <FormLabel error={hasError("error-message")} id="error-message">
            <LabelText>Error message (optional)</LabelText>
            {hasError("error-message") && <ErrorText>Enter the error message you received (between 10 and 400 characters)</ErrorText>}
            <Textarea forwardRef={errorMessageInputRef} maxLength="400" />
            <HintText>Copy and paste any error messages.</HintText>
            <HintText>Minimum 10 characters, maximum 400 characters including spaces.</HintText>
          </FormLabel>

          <FormLabel error={hasError("issue-description")} id="issue-description">
            <LabelText>Issue description</LabelText>
            {hasError("issue-description") && <ErrorText>Enter what you did before the problem occurred (between 10 and 1,000 characters)</ErrorText>}
            <Textarea forwardRef={issueDescriptionInputRef} maxLength="1000" />
            <HintText>Provide more details of the issue.</HintText>
            <HintText>Minimum 10 characters, maximum 1,000 characters including spaces.</HintText>
          </FormLabel>

          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Submit</Button>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </section>
  )
}
