import React from 'react';

type CombineWorkspacesAndCostsProps = {
  workspaces: any,
  workspaceCosts: any
}

export const CombineWorkspacesAndCosts = ({ workspaces, workspaceCosts }: CombineWorkspacesAndCostsProps) => {
  let combinedArray: any = [];
  workspaces.map((workspace: any, i: number) => {
    const combined = Object.assign({}, workspace, {costs: workspaceCosts.filter((item: any) => item.workspace_id === workspace.id)});
    combinedArray.push(combined);
  })
  return (
    combinedArray
  );
}

export const devWorkspaceCosts = [
  {
    "partition_key": "None",
    "row_key": "aa0ef4e2-0b45-4d41-988b-e0ec59e0272e",
    "timestamp": "2024-04-15T08:45:03.0516138Z",
    "workspace_id": "28a271c0-7873-42e9-8c9a-aa3c5662fb75",
    "credit_limit": "1000.0",
    "available_credit": "300.0",
    "credit_percentage_usage": "70",
    "update_time": "1708593415"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "48ac3975-d0c2-424c-b7d6-11fb461271c2",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "75",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "59b7741c-d7a4-4343-bcec-5277923740d2",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "15",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "38845d6f-183f-416c-b84a-3138cdcebff3",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "75",
    "update_time": "1713170821"
  }
];

export const testWorkspaceCosts = [
  {
    "partition_key": "None",
    "row_key": "aa0ef4e2-0b45-4d41-988b-e0ec59e0272e",
    "timestamp": "2024-04-15T08:45:03.0516138Z",
    "workspace_id": "08e26003-02b9-4c06-8862-37bbc28299e1",
    "credit_limit": "1000.0",
    "available_credit": "300.0",
    "credit_percentage_usage": "70",
    "update_time": "1708593415"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "d1b55d96-a705-42e1-ad0d-f8cacd41eaff",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "15",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "dbb98033-9b2d-4f34-a80d-08d49efd27ab",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "24",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "cbd05b03-6972-4c4f-b580-52e99f7e78f0",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "38",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "8374b0e9-ce36-4b83-b221-2be526b6715b",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "91",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "1ca74c0e-80c6-4318-b92e-cd13e70fa81a",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "66",
    "update_time": "1713170821"
  },
  {
    "partition_key": "None",
    "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
    "timestamp": "2024-04-15T08:47:22.8229331Z",
    "workspace_id": "603f9b76-56c9-448e-bdc9-2820100cadb1",
    "credit_limit": "2000.0",
    "available_credit": "1500.0",
    "credit_percentage_usage": "43",
    "update_time": "1713170821"
  }
];
