import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { SkipLink } from "govuk-react";
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';

import { useInterval } from '../hooks/useInterval';
import { IsOnlineContext } from '../../contexts/IsOnlineContext';
import { FooterBar } from "./FooterBar";
import { NavigationBar } from "./NavigationBar";
import { HeaderBar } from "./HeaderBar";
import { MainDisplay } from "./MainDisplay";
import { SafeInsetText } from '../ui/GDS-components/SafeInsetText';
import { AppRolesContext } from '../../contexts/AppRolesContext';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props: { children: any; }) => {
	const { instance } = useMsal();
	const isOnlineCtx = useContext(IsOnlineContext);
	const AppRolesCtx = useContext(AppRolesContext);
	const { pathname } = useLocation();
	// session timeout vars
	const [userState, setUserState] = useState<string>("Active");
	const [actions, setActions] = useState<number>(0);
	const [remainingSeconds, setRemainingSeconds] = useState<number | null>();

	// session timeout functions
	const onIdle = () => {
		setUserState("Idle");

		AppRolesCtx && AppRolesCtx.roles.length > 0 && (
			instance.logoutRedirect({
				postLogoutRedirectUri: "/",
			})
		);
	}

	const onActive = () => {
		setUserState("Active");
	}

	const onAction = () => {
		setActions(actions + 1);
	}

	const { getRemainingTime } = useIdleTimer({
		onIdle,
		onActive,
		onAction,
		timeout: 1000 * 60 * 60,
		throttle: 500
	})

	useInterval(() => setRemainingSeconds(Math.ceil(getRemainingTime() / 1000)), AppRolesCtx && AppRolesCtx.roles.length > 0 ? 500 : null);

	useEffect(() => {
		(!pathname.includes("#exports") || !pathname.includes("#imports")) && window.scrollTo(0,0);
	}, [pathname]);

	return (
		<>
			<SkipLink />
			<HeaderBar />
			<div className="govuk-width-container layout-container">
				<NavigationBar />
				<AuthenticatedTemplate>
					<SafeInsetText online={isOnlineCtx.isOnline}>
						You are {isOnlineCtx.isOnline ? "outside" : "inside"} CPRD Safe
					</SafeInsetText>
				</AuthenticatedTemplate>
				<MainDisplay children={props.children} />
			</div>
			<footer>
				<FooterBar children={undefined}/>
			</footer>
		</>
	);   
};
