import React, { useEffect, useState } from "react";
import TableBody from "./TableBody";
import TableHead from "./useTableHead";
import Pagination from "../../ui/Pagination";

import './Table.css';

type TableProps = {
  caption?: string;
  columns: any;
  data: any;
  sort: string | null | undefined;
  updatedWorkspace?: string | null;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  sortData: any;
  searchedTerm: string | null | undefined;
  onSearch: any;
  setWorkspaceUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  canSeeCosts: boolean;
  setCanSeeCosts: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Table = ({
  caption,
  data,
  columns,
  sort,
  updatedWorkspace,
  setRefresh,
  sortData,
  searchedTerm,
  onSearch,
  setWorkspaceUpdating,
  canSeeCosts,
  setCanSeeCosts
}: TableProps) => {
  const [paramData, setParamData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    setParamData(data.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [data, currentPage, sort, searchedTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sort]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = data && Math.ceil(data.length / recordsPerPage);
  
  return (
    <>
      <table className="table-component">
        {caption && <caption>{caption}</caption>}
        <TableHead columns={columns} cupFunc={() => sortData("credit_percentage_usage")} />
        <TableBody
          tableData={paramData}
          sort={sort}
          updatedWorkspace={updatedWorkspace}
          setRefresh={setRefresh}
          sortData={sortData}
          searchedTerm={searchedTerm}
          onSearch={onSearch}
          setWorkspaceUpdating={setWorkspaceUpdating}
          canSeeCosts={canSeeCosts}
          setCanSeeCosts={setCanSeeCosts}
        />
      </table>
      {nPages > 1 && (
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default Table;
