import React from "react";
import { SectionBreak, Heading, HintText } from "govuk-react";

import { SummaryList } from "../../../ui/GDS-components/SummaryList";
import { Fieldset } from "../../../ui/Fieldset";
import { RadioButton } from "../../../ui/RadioButton";
import { Checkbox } from "../../../ui/Checkbox";

export const ExportTriageData = ({ request }: any) => {
  return (
    <>
      {request.triageStatements.length > 0 && (
        <>
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Safe output criteria" greyHeader>
            <Fieldset id="rdgConsistent">
              <legend>
                <p className="export-triage-data__legend">Are requested outputs are consistent with the RDG approved protocol associated with this workspace?</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  name="rdgConsistent-yes"
                  checked={request.triageStatements[0].rdgConsistent === true}
                  disabled
                />
                <RadioButton
                  label="No"
                  name="rdgConsistent-no"
                  checked={request.triageStatements[0].rdgConsistent === false}
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="patientLevelData">
              <legend>
                <p className="export-triage-data__legend">Are there any event or patient level data are included in the requested outputs?</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  checked={request.triageStatements[0].patientLevelData === true}
                  name="patientLevelData-yes"
                  disabled
                />
                <RadioButton
                  label="No"
                  checked={request.triageStatements[0].patientLevelData === false}
                  name="patientLevelData-no"
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="requestedOutputsClear">
              <legend>
                <p className="export-triage-data__legend">All requested outputs are sufficiently clear and comprehensible to permit output checking without the need for dataset- or project-specific knowledge.</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  checked={request.triageStatements[0].requestedOutputsClear === true}
                  name="requestedOutputsClear-yes"
                  disabled
                />
                <RadioButton
                  label="No"
                  checked={request.triageStatements[0].requestedOutputsClear === false}
                  name="requestedOutputsClear-no"
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="requestedOutputsStatic">
              <legend>
                <p className="export-triage-data__legend">Are all requested outputs static?</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  checked={request.triageStatements[0].requestedOutputsStatic === true}
                  name="requestedOutputsStatic-yes"
                  disabled
                />
                <RadioButton
                  label="No"
                  checked={request.triageStatements[0].requestedOutputsStatic === false}
                  name="requestedOutputsStatic-no"
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="requestedOutputsPermittedFiles">
              <legend>
                <p className="export-triage-data__legend">Do all requested outputs use permitted file types?</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  checked={request.triageStatements[0].requestedOutputsPermittedFiles === true}
                  name="requestedOutputsPermittedFiles-yes"
                  disabled
                />
                <RadioButton
                  label="No"
                  checked={request.triageStatements[0].requestedOutputsPermittedFiles === false}
                  name="requestedOutputsPermittedFiles-no"
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="hiddenInformation">
              <legend>
                <p className="export-triage-data__legend">Is there any hidden information included (e.g., embedded files, comments, track changes).</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  checked={request.triageStatements[0].hiddenInformation === true}
                  name="hiddenInformation-yes"
                  disabled
                />
                <RadioButton
                  label="No"
                  checked={request.triageStatements[0].hiddenInformation === false}
                  name="hiddenInformation-no"
                  disabled
                />
              </div>
            </Fieldset>
          </SummaryList>
        </>
      )}
      {request.statisticsStatements.length > 0 && (
        <>
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Statistics information" greyHeader>
            <Fieldset id="codeLists">
              <legend>
                <p className="export-triage-data__legend">The output only contains code lists or programming code</p>
              </legend>
              <div className="export-triage-data__radio-wrapper">
                <RadioButton
                  label="Yes"
                  name="codeLists-yes"
                  checked={request.statisticsStatements[0].codeLists === true}
                  disabled
                />
                <RadioButton
                  label="No"
                  name="codeLists-no"
                  checked={request.statisticsStatements[0].codeLists === false}
                  disabled
                />
              </div>
            </Fieldset>
            <Fieldset id="statisticalTests">
              <legend>
                <Heading>Safe statistics</Heading>
              </legend>
              <HintText>Select all that apply</HintText>
              <Checkbox
                label="Statistical hypothesis tests (e.g., t-test, chi-square, R-square, standard errors)"
                checked={request.statisticsStatements[0].statisticalTests}
                id="statistical-tests"
                disabled
              />
              {request.statisticsStatements[0].statisticalTests && (
                <Fieldset className="export-triage-data__indented-item" id="statisticalTestsConfirmation">
                  <Checkbox
                    label="Please confirm that your tests were run on a minimum of five patients."
                    checked={request.statisticsStatements[0].statisticalTestsConfirmation}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Coefficients of association (e.g., estimated coefficients, models, AN(C)OVA, correlation tables, density plots, kernel density plots)"
                checked={request.statisticsStatements[0].coefficientsAssociation}
                id="coefficients-association"
                disabled
              />
              {request.statisticsStatements[0].coefficientsAssociation && (
                <Fieldset className="export-triage-data__indented-item" id="coefficientsAssociationResidualDegrees">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include coefficients of association. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="The residual degrees of freedom (number of observations less number of variables) exceeds five"
                    checked={request.statisticsStatements[0].coefficientsAssociationResidualDegrees}
                    disabled
                  />
                  <Checkbox
                    label="The model is not saturated (i.e., not all variables are categorical and fully interacted)"
                    checked={request.statisticsStatements[0].coefficientsAssociationModelNotSaturated}
                    disabled
                  />
                  <Checkbox
                    label="Your outputs do not include a regression with a single binary explanatory variable"
                    checked={request.statisticsStatements[0].coefficientsAssociationRegressionNotIncluded}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Shape (e.g., standard deviation, skewness, kurtosis)"
                checked={request.statisticsStatements[0].shape}
                id="shape-type"
                disabled
              />
              {request.statisticsStatements[0].shape && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include statistics of shape. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="Any standard deviations are greater than zero"
                    checked={request.statisticsStatements[0].shapeStandardDeviations}
                    disabled
                  />
                  <Checkbox
                    label="All statistics of shape were calculated for a minimum of five patients or GP practices"
                    checked={request.statisticsStatements[0].shapeMinFive}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Mode"
                checked={request.statisticsStatements[0].mode}
                id="mode-type"
                disabled
              />
              {request.statisticsStatements[0].mode && (
                <Fieldset className="export-triage-data__indented-item">
                  <Checkbox
                    label="You stated that your requested outputs include modes. Please confirm that the mode is not the only value (i.e., that different observations have different values)"
                    checked={request.statisticsStatements[0].modeConfirmation}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Non-linear concentration ratios (e.g., Herfindahl-Hirchsmann index, diversity index)"
                checked={request.statisticsStatements[0].ratios}
                id="ratio-type"
                disabled
              />
              {request.statisticsStatements[0].ratios && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include non-linear concentration ratios. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="N>2"
                    checked={request.statisticsStatements[0].ratiosConfirmationNRatios}
                    disabled
                  />
                  <Checkbox
                    label="H<0.81"
                    checked={request.statisticsStatements[0].ratiosConfirmationHRatios}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Gini coefficients or Lorenz curves"
                checked={request.statisticsStatements[0].giniCoefficients}
                id="gini-coefficients"
                disabled
              />
              {request.statisticsStatements[0].giniCoefficients && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include Gini coefficients or Lorenz curves. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="N>2"
                    checked={request.statisticsStatements[0].giniCoefficientsConfirmationN}
                    disabled
                  />
                  <Checkbox
                    label="The coefficient is less than 100%"
                    checked={request.statisticsStatements[0].giniCoefficientsConfirmationLessThan}
                    disabled
                  />
                </Fieldset>
              )}
            </Fieldset>
            <Fieldset id="unsafe-statistics">
              <legend>
                <Heading>Unsafe statistics</Heading>
              </legend>
              <HintText>Select all that apply</HintText>
              <Checkbox
                label="Frequencies (e.g. frequency tables, histograms, shares, alluvial flow graphs, heat maps, line graphs, pie charts, scatter graphs, scatter plots, smoothed histograms, waterfall charts)"
                checked={request.statisticsStatements[0].frequencies}
                id="frequency-type"
                disabled
              />
              {request.statisticsStatements[0].frequencies && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include frequencies. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="All counts <5 and frequencies derived from groups containing <5 patients or GP practices have been suppressed"
                    checked={request.statisticsStatements[0].frequenciesSmallFrequenciesSuppressed}
                    disabled
                  />
                  <Checkbox
                    label="All zeroes and full cells (100%) are evidential or structural (i.e., something you would expect)"
                    checked={request.statisticsStatements[0].frequenciesZerosFullCells}
                    disabled
                  />
                  <Checkbox
                    label="Underlying values are genuinely independent (i.e., they do not come from the same patient, the patients do not all have the same family number and do not all come from the same GP practice)"
                    checked={request.statisticsStatements[0].frequenciesUnderlyingValuesIndependent}
                    disabled
                  />
                  <Checkbox
                    label="The categories are comprehensive and apply to all data (i.e., all categories of each categorical variable are presented)."
                    checked={request.statisticsStatements[0].frequenciesCategoriesComprehensiveData}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Position (e.g., median, percentiles, box plots)"
                id="position-type"
                checked={request.statisticsStatements[0].position}
                disabled
              />
              {request.statisticsStatements[0].position && (
                <Fieldset className="export-triage-data__indented-item">
                  <Checkbox
                    label="You stated that your requested outputs include statistics of position. Please confirm the numbers for each group (and complementary groups) are ≥5"
                    checked={request.statisticsStatements[0].positionConfirmation}
                    disabled
                  />
                  <Fieldset id="isAcroUsedPosition">
                    <legend>
                      <p className="export-triage-data__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                    </legend>
                    <div className="export-triage-data__radio-wrapper">
                      <RadioButton
                        label="Yes"
                        name="isAcroUsedPosition-yes"
                        checked={request.statisticsStatements[0].isAcroUsedPosition === true}
                        disabled
                      />
                      <RadioButton
                        label="No"
                        name="isAcroUsedPosition-no"
                        checked={request.statisticsStatements[0].isAcroUsedPosition === false}
                        disabled
                      />
                    </div>
                  </Fieldset>
                </Fieldset>
              )}
              <Checkbox
                label="Extreme values (e.g., maxima, minima)"
                id="extreme-values"
                checked={request.statisticsStatements[0].extremeValues}
                disabled
              />
              {request.statisticsStatements[0].extremeValues && (
                <Fieldset className="export-triage-data__indented-item">
                  <Checkbox
                    label="You stated that your requested outputs include extreme values. Please confirm the maximum or minimum presented are non-informative and structural."
                    checked={request.statisticsStatements[0].extremeValuesConfirmation}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Linear aggregates (e.g., means, totals, simple indexes, linear correlation ratios, bar graphs, mean plots)"
                id="linear-aggregates"
                checked={request.statisticsStatements[0].linearAggregates}
                disabled
              />
              {request.statisticsStatements[0].linearAggregates && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include linear aggregates. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="The linear aggregates have been derived from groups containing ≥5 patients or GP practices."
                    checked={request.statisticsStatements[0].linearAggregatesDerivedGroups}
                    disabled
                  />
                  <Checkbox
                    label="The P-ratio dominance rule has been calculated and is greater than 10%. (NB: ACRO will check this automatically)."
                    checked={request.statisticsStatements[0].linearAggregatesPRatioDominanceRule}
                    disabled
                  />
                  <Checkbox
                    label="The N-K dominance rule has been calculated for the 2 largest values and is less than 90%. (NB: ACRO will check this automatically)."
                    checked={request.statisticsStatements[0].linearAggregatesNKDominanceRule}
                    disabled
                  />
                  <Fieldset id="isAcroUsedLinearAggregates">
                    <legend>
                      <p className="export-triage-data__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                    </legend>
                    <div className="export-triage-data__radio-wrapper">
                      <RadioButton
                        label="Yes"
                        name="isAcroUsedLinearAggregates-yes"
                        checked={request.statisticsStatements[0].isAcroUsedLinearAggregates === true}
                        disabled
                      />
                      <RadioButton
                        label="No"
                        name="isAcroUsedLinearAggregates-no"
                        checked={request.statisticsStatements[0].isAcroUsedLinearAggregates === false}
                        disabled
                      />
                    </div>
                  </Fieldset>
                </Fieldset>
              )}
              <Checkbox
                label="Odds ratios, risk ratios or other proportionate risks"
                id="odd-ratios"
                checked={request.statisticsStatements[0].oddsRatios}
                disabled
              />
              {request.statisticsStatements[0].oddsRatios && (
                <Fieldset className="export-triage-data__indented-item">
                  <Checkbox
                    label="You stated that your requested outputs include odds ratios, risk ratios or other proportionate risks. Please confirm the underlying contingency table has been produced and is included in the requested outputs"
                    checked={request.statisticsStatements[0].oddsRatiosConfirmation}
                    disabled
                  />
                </Fieldset>
              )}
              <Checkbox
                label="Hazard and survival tables (e.g., tables of survival/death rates, Kaplan-Meier graphs)"
                id="hazard-survival-tables"
                checked={request.statisticsStatements[0].hazardSurvivalTables}
                disabled
              />
              {request.statisticsStatements[0].hazardSurvivalTables && (
                <Fieldset className="export-triage-data__indented-item">
                  <legend>
                    <p className="export-triage-data__legend">You stated that your requested outputs include hazard or survival tables. Please confirm the following:</p>
                  </legend>
                  <Checkbox
                    label="The number of patients who survived is ≥5"
                    checked={request.statisticsStatements[0].hazardSurvivalTablesNumberPatientsSurvived}
                    disabled
                  />
                  <Checkbox
                    label="Exit dates are relative, not absolute"
                    checked={request.statisticsStatements[0].hazardSurvivalTablesExitDatesRelatives}
                    disabled
                  />
                  <Checkbox
                    label="There are no dates with a single exit"
                    checked={request.statisticsStatements[0].hazardSurvivalTablesNoDatesWithSingleExit}
                    disabled
                  />
                </Fieldset>
              )}
            </Fieldset>
            <Fieldset id="other">
              <legend>
                <Heading>Other</Heading>
              </legend>
              <Checkbox
                label="There are other statistics, or types of output, included"
                checked={request.statisticsStatements[0].other}
                disabled
              />
            </Fieldset>
          </SummaryList>
        </>
      )}
      {request.contactTeamForm.length > 0 && (
        <>
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Exemption" greyHeader>
            <p><strong>Why are outputs required that do not align with the disclosure control rules?</strong></p>
            <p>{request.contactTeamForm[0].requiredDisclosureAlignment}</p>
            <SectionBreak level="MEDIUM" visible />
            <p><strong>What measures have been taken to minimise the risk of potentially disclosive outputs?</strong></p>
            <p>{request.contactTeamForm[0].measuresTakenMinimiseDisclosure}</p>
            <SectionBreak level="MEDIUM" visible />
            <p><strong>Will the outputs be transferred to any other third party?</strong></p>
            <p>{request.contactTeamForm[0].transferToThirdParty}</p>
          </SummaryList>
        </>
      )}
    </>
  )
}
