import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { LoadingBox } from "govuk-react";
import yn from "yn";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { MessageCard } from "../Error/MessageCard";

import { CreateVMForm } from "./CreateVMForm";
import { ResourceVM } from "../models/resource";
import { ApiEndpoint } from "../models/apiEndPoints";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { WorkspaceRoleName } from "../models/roleNames";

import { Title } from "../ui/Title";
import { Lede } from "../ui/Lede";

export const CreateVM=(props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [responseData, setresponseData] = useState(null);
  const [newResourceVMData, setnewResourceVMData] = useState<ResourceVM>({} as ResourceVM);
  const [errorData, setErrorData] = useState<Error | boolean>(false);
  const [isRequestValid, setRequestValid] = useState(false);
  const [currentVMs, setCurrentVMs] = useState<any>(null);
  const workspaceCtx = useContext(WorkspaceContext);

  const isResearcher = workspaceCtx.roles.length === 1 && workspaceCtx.roles[0] === WorkspaceRoleName.WorkspaceResearcher;

  let paramIds = useParams();
  let navigate = useNavigate();

  let finalurl=`${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${paramIds.id}/${ApiEndpoint.WorkspaceServices}/${paramIds.rid}/${ApiEndpoint.UserResources}`;    
    
  const VMHandler = useCallback(async (newResourcVMRequest:any) => {
    setErrorData({} as Error);
    setnewResourceVMData(newResourcVMRequest);

    if ((newResourcVMRequest.properties.display_name?.length > 0)) {
      if(workspaceCtx.workspace.properties!==undefined && workspaceCtx.workspace.id == paramIds.id) {
        setIsLoading(true);
        yn(process.env.REACT_APP_DEBUG) && console.log('Trying to submit the request...');
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
          scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
        }).then(async (response) => {
          await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Post,newResourcVMRequest)
            .then(response => {
              setresponseData(response);
              navigate(`/workspaces/${paramIds.id}/workspace-services/${paramIds.rid}/user-resources`);
            })
            .catch((err:any) => {
              setErrorData(err);
              setIsLoading(false);
            });
          })
          .catch((err:any) => {
            setErrorData(err);
            setIsLoading(false);                
          })           
        yn(process.env.REACT_APP_DEBUG) && console.log('New request submitted...');
      }
    } else {
      console.log('VM name: length is smaller then expected...')
    }
  }, [setnewResourceVMData, accounts, finalurl, instance, isRequestValid, paramIds.id, workspaceCtx]);

  useEffect(() => {
    newResourceVMData.properties ?
    setRequestValid((newResourceVMData.properties.display_name?.length > 0)):<></>
  }, [newResourceVMData,setRequestValid]);

  const retrieveVMs = async () => {
    setIsLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response: any) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${paramIds.id}/${ApiEndpoint.WorkspaceServices}/${paramIds.rid}/${ApiEndpoint.UserResources}`,
        HttpMethod.Get,
        null
      ).then(response => {
        setCurrentVMs(response.userResources);
        setIsLoading(false);
      }).catch((err: any) => {
        console.log("err: ", err);
        setIsLoading(false);
      })
    })
  }

  console.log("currentVMs: ", currentVMs);

  useEffect(() => {
    isResearcher && workspaceCtx.workspace.properties && retrieveVMs();
  }, [isResearcher]);

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      <Title>Create a new VM</Title>
      <LoadingBox loading={isLoading}>
        {isResearcher ? (
          <>
            {currentVMs && currentVMs.length < 1 ? (
              <CreateVMForm onAddImportRequest={VMHandler} />
            ) : (
              <Lede>You can only create one VM for this workspace.</Lede>
            )}
          </>
        ) : (
          <CreateVMForm onAddImportRequest={VMHandler} />
        )}
      </LoadingBox>
    </>
  );
}
