import React from "react";

import { Th } from "./GDS-components/Table";

import "./TableHead.css";
import { ButtonWithSort } from "./ButtonWithSort";

type TableHeadProps = {
  headers: {
    header: string;
    colSpan?: number;
    cupFunc?: () => void;
  }[]
}

export const TableHead = ({ headers }: TableHeadProps) => {
  return (
    <thead>
      <tr>
        {headers.map((header: any, i: number) => {
          return header.header !== "CUP" ? (
            <Th key={`${header.header}-${i}`} colspan={header.colSpan}>{header.header}</Th>
          ) : (
            <Th key={`${header.header}-${i}`} colspan={header.colSpan}>
              <ButtonWithSort onClick={header.cupFunc}>{header.header}</ButtonWithSort>
            </Th>
          )
        })}
      </tr>
    </thead>
  )
}
