import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import yn from "yn";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ApiEndpoint } from "../models/apiEndPoints";
import { WorkspaceUserResourcesData } from "./WorkspaceUserResourcesData";
import { CheckSingleOps } from "../../services/CheckOps";
import { useInterval } from "../hooks/useInterval";

export const WorkspaceUserResources = (props: any) => {
  const workspaceCtx = useContext(WorkspaceContext);
  const { instance, accounts } = useMsal(); 
  const [errorData, setErrorData] = useState(Error);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setresponseData] = useState<any>(null);

  const [refresh, setRefresh] = useState(false);
  const [updatedVm, setUpdatedVm] = useState<null | string>(null);
  const [serviceUpdating, setServiceUpdating] = useState(false);
  const [isParentServicePaused, setParentServicePaused] = useState(false);
  let wsId = useParams();
  
  // this one!!!!!!!
  const LoadVSResources = async () => {
    if (workspaceCtx.workspace.properties!==undefined && workspaceCtx.workspace.id == wsId.id) {
      setIsLoading(true);
      yn(process.env.REACT_APP_DEBUG) && console.log('WorkspaceUserResource: executing...')
      let finalurl =  `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.WorkspaceServices}/${wsId.rid}/${ApiEndpoint.UserResources}`;
      
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
      }).then(async (response: any) => {
        await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Get,null).then(response => setresponseData(response))
        .catch((err:any)=> setErrorData(err));              
      }).catch((err:any)=> {
        setErrorData(err);
      })
      setIsLoading(false);
    }
  }

  // for the parent workspace service
  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${wsId.rid}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        CheckSingleOps(response.operations, setServiceUpdating, setRefresh, serviceUpdating);
      })
    })
  }

  // If parent workspace is paused, we need to disable the VM
  const getParentServiceStatus = () => {
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response: any) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${wsId.rid}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setParentServicePaused(!response.workspaceService.isEnabled);
        })
      })
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      LoadVSResources()
    }, 10000)
    
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    workspaceCtx.workspace.properties && LoadVSResources();
    workspaceCtx.workspace.properties && callOperations();
    workspaceCtx.workspace.properties && getParentServiceStatus();
    refresh && setRefresh(false);
    refresh && setUpdatedVm(null);
  }, [workspaceCtx, refresh]);
    //yn(process.env.REACT_APP_DEBUG) && console.log('WorkspaceUserResource: USER RESOURCES...',responseData);

  useInterval(() => callOperations(), serviceUpdating ? 10000 : null);

  return (
    <>
      {isLoading && "Loading..."}
      {responseData && (
        <WorkspaceUserResourcesData
          trecoreData={responseData}
          updatedVm={updatedVm}
          setRefresh={setRefresh}
          serviceUpdating={serviceUpdating}
          isParentServicePaused={isParentServicePaused}
        />
      )}
    </>
  );
}