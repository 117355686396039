import { ButtonWithSort } from "../../ui/ButtonWithSort";
import { Th } from "../../ui/GDS-components/Table";


const useTableHead = ({ columns, cupFunc }:{ columns:any, handleSorting?:any, cupFunc?:any}) => {

  return (
    <thead>
      <tr>
        {columns.map(({ label, accessor }:{ label:any, accessor:any }) => {
          // const header = label === "CUP" ? 
          return label !== "CUP" ? (
            <Th key={accessor}>{label}</Th>
          ) : (
            <Th key={accessor}>
              <ButtonWithSort onClick={cupFunc}>{label}</ButtonWithSort>
            </Th>
          );
        })}
      </tr>
    </thead>
  );
};

export default useTableHead;
